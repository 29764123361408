import React from 'react';
import { Link } from 'gatsby';
import styles from './Title.module.scss';

const Title = ({ title, isIndex }) => (
  <div>
    { isIndex ? (
    <h2 className={styles['title__main']}>
        <Link className={styles['title__main-link']} to="/">{title}</Link>
    </h2>
    ) : (
    <h3 className={styles['title__main']}>
        <Link className={styles['title__main-link']} to="/">{title}</Link>
    </h3>
    )}
  </div>
);

export default Title;
